
function submitForm() {
    var extradata = $('#contact-form').serialize();
    $('#contact-form button .spinner').css('display','inline-block');
    $('#contact-form button').attr('disabled','disabled');
    $.post("/ajax/contact_us", extradata, function (res) {
        if (res.state == 'error') {

            var firstError = null;
            $('#contact-form button .spinner').css('display','none');
            $('#contact-form button').attr('disabled',false);
            $("div.errordiv").remove();
            $(".errorredborder").removeClass("errorredborder");

            $.each(res.errors, function (obj, str) {
                var err = "<div class='error errordiv'>" + str + "</div>";
                $('[name=' + obj + ']:first').addClass("errorredborder").parent().append(err)
                if (firstError == null) {
                    firstError = $('[name=' + obj + ']:first')
                }
            });
            if (firstError != null) {
                $(window).scrollTop(firstError.parents('.form-group').offset().top);
            }
        }else{
            $("div.errordiv").remove();
            $(".errorredborder").removeClass("errorredborder");
            $(".success-message").removeClass("hide");
            $(".form-control").val("");
            $('#contact-form button .spinner').css('display','none');
            setTimeout(function(){ $(".success-message").addClass("hide"); }, 5000);
        }
    }, 'json');
    return false;
}